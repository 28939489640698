
<script setup>
import { ref, defineProps, defineEmits, onMounted, onBeforeUnmount, onActivated, onDeactivated } from "vue";
import { hapticsImpact } from "./utils.ts";

const props = defineProps({
    main: { type: Boolean },
    showSpinner: { type: Boolean },
    dataObject: { type: Object },
    pullRefresh: { type: [Boolean, Function] },
    ignoreContentAbsence: { 
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(["loadNextPage"]);

const content = ref(null);

let timeout = null;
let lastTime = 0;

const pullSpinner = ref(null);

let scrollTop = 0;

function onScroll(e) {
    if (!content.value) {
        return;
    }
    const curTime = Date.now();
    if ((curTime - lastTime) < 500) {
        clearTimeout(timeout);
        timeout = setTimeout(onScroll, 500);
        return;
    }
    //clearTimeout(timeout);
    //timeout = setTimeout(() => {
    const rem = content.value.scrollHeight - content.value.scrollTop - content.value.offsetHeight;
    const rem_t = rem / content.value.scrollHeight;
    // if (rem < 1000) {
    if (rem_t < 0.5 && rem < 5000) {
        if (props.dataObject) {
            props.dataObject.loadNextPage();
        }
        emit("loadNextPage");
    }
    //}, 100);
    lastTime = curTime;
}


const state = {};

function onTouchStart(e) {
    state.x = 0;
    state.y = 0;

    state.vel_x = 0;
    state.vel_y = 0;

    state.prev_x = e.touches[0].clientX;
    state.prev_y = e.touches[0].clientY;
    state.prev_t = e.timeStamp;

    state.cancel = false;
    state.cancelPull = false;
    state.prevent = false;

    state.firstFrame = true;
}

function onTouchMove(e) {
    //console.log("onTouchMove", e);


    // precalculate
    const dif_x = e.touches[0].clientX - state.prev_x;
    const dif_y = e.touches[0].clientY - state.prev_y;

    /*
    if (state.firstFrame) {
        // only allow downward swipe
        if (Math.abs(dif_x) > Math.abs(dif_y)) {
            state.cancel = true;
        }
        if (dif_y < 0) {
            state.cancel = true;
        }
    }
    */

    if (content.value.scrollTop === 0 && dif_y > 0) {
    }

    if (state.firstFrame) {
        if (Math.abs(dif_x) > Math.abs(dif_y)) {
            state.cancel = true;
        }
    }

    if (state.cancel) {
        pullSpinner.value.display = "none";
        return;
    } else {
        //e.preventDefault();
    }

    if (content.value.scrollTop < 0 && dif_y > 0) {
        //e.preventDefault();
    } else if (content.value.scrollTop === 0 && dif_y < 0) {
        // allow scroll
    } else if (content.value.scrollTop !== 0) {
        // allow scroll
    } else if (content.value.scrollTop === 0 || dif_y > 0) {
        state.prevent = true;
        //e.preventDefault();
    }

    if (state.prevent) {
        e.preventDefault();
    }

    if (content.value.scrollTop < -(250 ** 0.75)) {
        if (!state.cancelPull) {
            state.cancelPull = true;
            if (props.pullRefresh instanceof Function) {
                props.pullRefresh();
            }
            if (props.dataObject) {
                props.dataObject.load();
            }
            content.value.style.transition = "all 250ms";
            content.value.style.transform = `translate(0%, 0%)`;
            hapticsImpact("HeavyImpact");
        }
    }

    if (pullSpinner.value) {
        pullSpinner.value.display = "flex";
    }




    // update state
    /*
    state.x += dif_x;
    state.y += dif_y;
    */

    if (dif_y > 0) {
        if (content.value.scrollTop === 0) {
            state.y += dif_y;
        }
    } else {
        state.y += dif_y;
    }
    if (state.y < 0) {
        state.y = 0;
    }

    /*
    if (content.value.scrollTop === 0 && dif_y > 0) {
        state.y += dif_y;
    } else if (dif_y < 0) {
        state.y += dif_y;
    }
    if (content.value.scrollTop > 0) {
        state.y = Math.min(state.y, content.value.scrollTop);
    }
    */




    state.vel_x = dif_x / (e.timeStamp - state.prev_t);
    state.vel_y = dif_y / (e.timeStamp - state.prev_t);

    state.prev_x = e.touches[0].clientX;
    state.prev_y = e.touches[0].clientY;
    state.prev_t = e.timeStamp;

    if (state.x < 0) {
        state.x = 0;
    }

    // update visual
    let offset_y = state.y ** 1;

    let transform_y = offset_y;

    //transform_y **= 0.75;
    transform_y **= 0.825;

    content.value.style.transition = "all 0ms";
    content.value.style.transform = `translate(0%, calc(${transform_y}px))`;

    const t = Math.min(Math.max((state.y - 50) / 200, 0), 1)

    const maxTransform = 250 ** 0.75;

    if (!state.cancelPull) {
        if (t === 1) {

            if (pullSpinner.value) {
                pullSpinner.value.display = "flex";
                pullSpinner.value.style.transition = "all 500ms ease-in-out";
                pullSpinner.value.style.opacity = "0%";
                pullSpinner.value.style.transform = `translate(-50%, calc(-50% + ${Math.min(transform_y, maxTransform)}px)) rotate(${(1 - (1 - t) ** 2) * 360 + 180}deg) scale(2)`;
            }

            state.cancelPull = true;
            if (props.pullRefresh instanceof Function) {
                props.pullRefresh();
            }
            if (props.dataObject) {
                props.dataObject.load();
            }
            content.value.style.transition = "all 250ms";
            content.value.style.transform = `translate(0%, 0%)`;
            hapticsImpact("HeavyImpact");
        } else {
            if (pullSpinner.value) {
                pullSpinner.value.style.transition = "all 0ms";
                pullSpinner.value.style.opacity = t * 100 + "%";
                pullSpinner.value.style.transform = `translate(-50%, calc(-50% + ${Math.min(transform_y, maxTransform)}px)) rotate(${(1 - (1 - t) ** 2) * 360}deg)`;

            }

        }
    }
    if (pullSpinner.value) {
        pullSpinner.value.style.opacity = "0%";
    }

    state.firstFrame = false;
}

function onTouchEnd(e) {
    if (pullSpinner.value) {
        pullSpinner.value.style.transition = "all 250ms";
        pullSpinner.value.style.opacity = "0";
    } 

    content.value.style.transition = "all 250ms";
    content.value.style.transform = `translate(0%, 0%)`;

    if (state.cancel) {
        if (pullSpinner.value) {
            pullSpinner.value.display = "none";
        }

        return;
    }
}

onMounted(() => {
    if (props.pullRefresh) {
        if (content.value) {
            content.value.addEventListener("touchstart", onTouchStart);
            content.value.addEventListener("touchmove", onTouchMove);
            content.value.addEventListener("touchend", onTouchEnd);
        }
    }
});

onBeforeUnmount(() => {
    if (content.value) {
        content.value.removeEventListener("touchstart", onTouchStart);
        content.value.removeEventListener("touchmove", onTouchMove);
        content.value.removeEventListener("touchend", onTouchEnd);
    }
});

</script>

<template>
    <div v-bind="$attrs" class="afm-content scrollable" :class="{ 'afm-main-content': props.main }" ref="content"
        @scroll="onScroll">
        <slot />

        <!-- spinner -->
        <template v-if="props.showSpinner || props.dataObject?.state?.isNextPageLoading">
            <div class="p-5 d-flex justify-content-center align-items-center" style="pointer-events: none;">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </template>

        <!-- empty state -->
        <template v-if="props.dataObject && !props.dataObject.state.isLoading && !props.dataObject.data.length && !ignoreContentAbsence">
            <div class="d-flex flex-column justify-content-center align-items-center gap-3 text-muted"
                style="height: 3rem;">
                <span>{{ $t("No results found") }}</span>
            </div>
        </template>
    </div>

    <!-- pull refresh -->
    <template v-if="pullRefresh && typeof pullRefresh === 'boolean'">
        <div ref="pullSpinner" class="d-flex justify-content-center align-items-center"
            style="position: absolute; top: 0; left: 50%; transform: translate(-50%, -50%); height: 3rem; pointer-events: none; opacity: 0%;">
            <i class="fas fa-spinner" />
        </div>
    </template>
</template>

<style scoped>
.afm-content {
    position: relative;

    flex-grow: 1;
    flex-shrink: 1;

    overflow-x: hidden;
    overflow-y: auto;
}
</style>
